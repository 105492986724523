<template>
    <div>
        <btn text="Open Modal" @click="$refs.SimpleModal.show()"></btn>
        <modal
               ref="SimpleModal" header-color="seventh" title="Premium plan" width="50r">
            <SubscriptionPlan></SubscriptionPlan>
        </modal>
    </div>
</template>

<script>
import SubscriptionPlan from '@/views/user-home/payment/SubscriptionPlan';
export default {
    name: 'SubscriptionPlanCard',
    components: { SubscriptionPlan }
};
</script>

<style scoped>

</style>
